import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import CustomRoutes from './routes';

import Header from "./header"
import Footer from "./footer"
import history from "./routes/history";
import Home from "./pages/home";
import LangSelection from "./_langSelection";
import i18next from "i18next";

class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.last_route = "";
    }

    render() {

        let route = window.location.pathname.substr(1)
        route = route === "" ? "index" : route

        if (this.last_route !== "" && route !== this.last_route)
            window.scrollTo(0, 0)

        this.last_route = route;

        const available_lang = ["fr", "de", "it", "en", "jp"];
        if (!available_lang.includes(localStorage.getItem("lang"))) {
            localStorage.setItem("lang", "en");
        }

        return (
            <div className="page_wrapper" id={"page_" + route + "_wrapper"}>
                <LangSelection/>
                <Header/>
                <div className="content_wrapper" id={"content_" + route + "_wrapper"}>
                    <CustomRoutes/>
                </div>
                <Footer/>
                {/*<CookieBanner/>*/}
            </div>
        );
    }
}

export default withRouter(Layout);
