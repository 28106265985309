import React from 'react';

const Header = () => {

	return (
		<header id="header">
			<a className="logo" href="/"/>
		</header>
	);
};

export default Header;

