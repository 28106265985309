import React, {useEffect, useState, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from "react-i18next";

import Animation from "../_animation";

// Import ../_langSelection.jsx (LangSelection is default export)
import LangSelection from "../_langSelection";

const Home = () => {
    const langSelectionRef = useRef();

    //trad
    const {t} = useTranslation("common");

    //calendrier
    const [token, setToken] = useState(null);
    //const [date, setDate]   = useState(/*null*/"03");
    const [date, setDate] = useState(null);

    //modal formulaire step 1
    const [showStep1, setShowStep1] = useState(false);

    const handleCloseStep1 = () => setShowStep1(false);
    const handleShowStep1 = () => {
        trackAction('Choix Date')
        setShowStep1(true)
    }

    //modal formulaire step Lose
    const [showStepLose, setShowStepLose] = useState(false);

    const handleCloseStepLose = () => setShowStepLose(false);
    const handleShowStepLose = () => setShowStepLose(true);

    //modal formulaire step gain
    const [showStepGain, setShowStepGain] = useState(false);
    const [showFormGain, setShowFormGain] = useState(false);

    const handleCloseStepGain = () => setShowStepGain(false);
    const handleShowStepGain = () => setShowStepGain(true);

    //popup merci
    const [showMerci, setShowMerci] = useState(false);

    const handleCloseMerci = () => setShowMerci(false);
    const handleShowMerci = () => setShowMerci(true);

    const [dotation, setDotation] = useState("STYLO1");
    const [code, setCode] = useState("KEITH15");
    const [codeValue, setCodeValue] = useState("");
    const [codeValidity, setCodeValidity] = useState("");

    const [showGameEndModal, setshowGameEndModal] = useState(false);

    //Animation
    const [showAnimation, setShowAnimation] = useState(false);
    const animationCallback = () => {
        //à la fin de l'animation on affiche le gain
        setShowAnimation(false);
        handleShowStepGain();
    }

    //récupération du premier token
    useEffect(() => {

        let formData = new FormData();
        formData.append('action', "getToken");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                credentials: "include",
                cors: true,
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    setToken(json.token);
                } else {
                    alert("Erreur lors de la connexion à l'API");
                }

            });
    }, []);

    //récupération de la date du jour
    useEffect(() => {

        if (token === null)
            return;

        let formData = new FormData();
        formData.append('action', "getDate");
        formData.append('nonce', token);

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                credentials: "include",
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    setDate(json.date);
                } else {
                    alert("Erreur lors de la connexion à l'API");
                }
            });
    }, [token]);

    //pattern regex
    const Pattern = {
        numberAndAlphaPattern_nospace: /^[0-9a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ\-',.]+$/,
        numberAndAlphaPattern: /^[0-9a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-',.]+$/,
        numberPattern: /^[0-9 ]+$/,
        alphaPattern: /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-',.]+$/,
        emailPattern: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    };

    //error message
    const MessageEmpty = {
        "prenom": t("modal_gain_phy.form.prenom.error.0"),
        "nom": t("modal_gain_phy.form.nom.error.0"),
        "adresse": t("modal_gain_phy.form.adresse.error.0"),
        "ville": t("modal_gain_phy.form.ville.error.0"),
        "cp": t("modal_gain_phy.form.cp.error.0"),
        "mail": t("modal_step1.form.mail.error.0"),
        "pays": t("modal_gain_phy.form.pays.error.0"),
        "telephone": t("modal_gain_phy.form.telephone.error.0"),
        "optin_reglement": t("modal_step1.form.optin_reglement.error.0"),
    };
    const MessageInvalid = {
        "prenom": t("modal_gain_phy.form.prenom.error.1"),
        "nom": t("modal_gain_phy.form.nom.error.1"),
        "adresse": t("modal_gain_phy.form.adresse.error.1"),
        "ville": t("modal_gain_phy.form.ville.error.1"),
        "cp": t("modal_gain_phy.form.cp.error.1"),
        "mail": t("modal_step1.form.mail.error.1"),
    };

    //form 1
    const {register: registerForm1, handleSubmit: handleSubmitForm1, formState: {errors: errorsForm1}} = useForm();
    const submitStep1 = (data) => {
        trackAction('Participation')

        submitRef.current.setAttribute("disabled", "disabled");

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append('nonce', token);
        formData.append('lang', localStorage.getItem("lang"));
        formData.append('lang_pays', localStorage.getItem("country"));
        formData.append('action', "participe_step1");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                if (json.success === true) {

                    if (json.message === "#cantplay") {
                        alert(t("error.cantplay"));

                    } else {
                        handleCloseStep1();
                        sessionStorage.setItem("participation", json.id_participation);

                        if (json.ig !== "PERDU") {
                            //gagné
                            setDotation(json.ig);

                            if (json.ig === "CODE") {
                                setCode(json.code);
                                setCodeValue(json.code_value);
                                setCodeValidity(json.code_validity);
                            }
                            setShowAnimation(true);
                            //handleShowStepGain();
                        } else {
                            //perdu
                            handleShowStepLose();
                        }
                    }

                } else {
                    alert(json.message);
                }

                submitRef.current.removeAttribute("disabled");
            });
    };
    const submitRef = useRef(null);

    //form 2
    const {register: registerForm2, handleSubmit: handleSubmitForm2, formState: {errors: errorsForm2}} = useForm();
    const submitStep2 = (data) => {
        trackAction('Formulaire')
        submitRef2.current.setAttribute("disabled", "disabled");

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append('nonce', token);
        formData.append('lang', localStorage.getItem("lang"));
        formData.append('participation', sessionStorage.getItem("participation"));
        formData.append('action', "participe_step2");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    handleCloseStepGain();
                    handleShowMerci();
                    sessionStorage.removeItem("participation");
                } else {
                    alert(json.message);
                }

                submitRef2.current.removeAttribute("disabled");

            });
    };
    const submitRef2 = useRef(null);

    const trackAction = (action, callback) => {
        fetch (
            process.env.REACT_APP_API_URL + '/eventlog.php',
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                cache: "no-cache",
                body: JSON.stringify({
                    action: action,
                    lang: localStorage.getItem("lang"),
                    country: localStorage.getItem("country"),
                    token: token
                })
            }
        )
        .then(response => {
            if (!response.ok) {
                if (callback) {
                    callback()
                }
                return Promise.reject(response)
            }
            return response.json()
        })
        .then(json => {
            if (json.success === true) {
                if (callback) {
                    callback();
                }
            } else {
                callback()
            }
        })
    }

    //check de la date d'opé
    const start_date = 1701385200;
    const end_date = new Date("2023-12-24").getTime() / 1000;

    const current_date = new Date().getTime() / 1000;
    const ope_end = current_date >= end_date;
    const ope_start = current_date >= start_date;

    useEffect(() => {
        langSelectionRef.current.handleShow()
    }, [ope_start, ope_end])

    //création des cases
    let cases = [];
    for (let i = 1; i <= 24; i++) {
        cases.push(<div key={`case${i}`} className={`case d-none d-lg-flex ${parseInt(date) === i && !ope_end && ope_start ? "active" : ""} ${parseInt(date) > i || ope_end || !ope_start ? "disabled" : ""}`}
            id={"case_" + i} onClick={parseInt(date) === i && !ope_end && ope_start ? handleShowStep1 : undefined}><span>{i}</span></div>);
    }

    return (
        <>
            <LangSelection ref={langSelectionRef} onClose={() => {
                if (ope_end || !ope_start) {
                    setshowGameEndModal(true)
                }
            }}/>
            {!ope_end && ope_start &&
                <>
                    {showAnimation && <Animation handleOnEnd={animationCallback}/>}

                    <Modal id={"modal_game_step1"} className={"modal_carandache"} show={showStep1}
                           onHide={handleCloseStep1}
                           backdrop="static" centered>
                        <Modal.Body>
                            <a className={"modal_close"} onClick={handleCloseStep1}/>
                            <div className={"modal_title"}>{t("modal_step1.title")}</div>
                            <p className={"modal_subtitle"}
                               dangerouslySetInnerHTML={{__html: t("modal_step1.subtitle")}}/>

                            <form onSubmit={handleSubmitForm1(submitStep1)}>
                                <div className={"form_row_wrapper"}>
                                    <div className={"input_wrapper"}>
                                        <input type={"text"} name={"mail"} id={"mail"}
                                               placeholder={t("modal_step1.form.mail.placeholder")} {...registerForm1(
                                            "mail",
                                            {
                                                required: {value: true, message: MessageEmpty.mail},
                                                pattern: {value: Pattern.emailPattern, message: MessageInvalid.mail}
                                            }
                                        )}/>
                                    </div>
                                </div>
                                <div className={"form_row_wrapper"}>
                                    <div className={"checkbox_wrapper"}>
                                        <input type={"checkbox"} name={"optin_nl"} id={"optin_nl"}
                                               value={"1"} {...registerForm1("optin_nl")} />
                                        <label htmlFor={"optin_nl"}>{t("modal_step1.form.optin_nl.label")}</label>
                                    </div>
                                </div>
                                <div className={"form_row_wrapper"}>
                                    <div className={"checkbox_wrapper"}>
                                        <input type={"checkbox"} name={"optin_reglement"} id={"optin_reglement"}
                                               value={"1"} {...registerForm1("optin_reglement", {
                                            required: {
                                                value: true,
                                                message: MessageEmpty.optin_reglement
                                            }
                                        })} />
                                        <label htmlFor={"optin_reglement"}
                                               dangerouslySetInnerHTML={{__html: t("modal_step1.form.optin_reglement.label")}}/>
                                    </div>
                                </div>
                                <div className={"error_wrapper"}>
                                    <span>{errorsForm1.mail && errorsForm1.mail.message}</span>
                                    <span>{errorsForm1.optin_reglement && errorsForm1.optin_reglement.message}</span>
                                </div>
                                <input type={"submit"} className={"cta"} value={t("modal_step1.form.cta")}
                                       ref={submitRef}/>
                                <div className={"mention"}
                                    dangerouslySetInnerHTML={{__html: t("modal_step1.form.mention")}}/>

                                <div className={"copyright_mention"}>
                                    <span>{t("copyright")}</span>
                                </div>
                            </form>

                        </Modal.Body>
                    </Modal>

                    <Modal id={"modal_game_stepgain"} className={"modal_carandache formStep_" + showFormGain + " dotationType_" + dotation} show={showStepGain}
                           onHide={handleCloseStepGain} backdrop="static" centered>
                        <Modal.Body>
                            <a className={"modal_close"} onClick={handleCloseStepGain}/>
                            {!showFormGain && dotation !== "CODE" &&
                                <>
                                    <div className={"modal_title"}>{t("modal_gain.title")}</div>
                                    <p className={"modal_subtitle"}
                                       dangerouslySetInnerHTML={{__html: t("modal_gain.subtitle").replace("#dotation#", t("modal_gain.dotations." + dotation))}}/>
                                    <div className={`dotation ${dotation}`}/>
                                    <a href={"#0"} className={"cta"}
                                       onClick={() => setShowFormGain(true)}>{t("modal_gain.cta")}</a>

                                    <div className={"copyright_mention"}>
                                        <span>{t("copyright")}</span>
                                    </div>
                                </>
                            }

                            {!showFormGain && dotation === "CODE" &&
                                <>
                                    <div className={"modal_title"}>{t("modal_gain_code.title")}</div>
                                    <p className={"modal_subtitle"}>{t("modal_gain_code.subtitle")}</p>
                                    <div className={`dotation_code`}>
                                        <div>-15%</div>
                                        <div>{t("modal_gain_code.code_label")}</div>
                                    </div>
                                    <p className={"modal_code"}
                                       dangerouslySetInnerHTML={{__html: t("modal_gain_code.code").replace("#code#", code)}}/>
                                    <p className={"modal_code_validity"}
                                       dangerouslySetInnerHTML={{__html: t("modal_gain_code.validity").replace("#code_validity#", codeValidity)}}/>
                                    <a
                                        onClick={() => {
                                            trackAction(
                                                "Lien Caran d'Ache code réduction",
                                                () => {
                                                    window.open(t("modal_gain_code.url"))
                                                }
                                            )}}
                                        className={"cta px-3"}
                                    >{t("modal_gain_code.cta")}</a>

                                    <div className={"copyright_mention"}>
                                        <span>{t("copyright")}</span>
                                    </div>
                                </>
                            }

                            {showFormGain &&
                                <>
                                    <div className={"modal_title"}>{t("modal_gain_phy.title")}</div>
                                    <p className={"modal_subtitle"}>{t("modal_gain_phy.subtitle")}</p>

                                    <form onSubmit={handleSubmitForm2(submitStep2)}>
                                        <div className={"form_row_wrapper"}>
                                            <div className={"input_wrapper"}>
                                                <input type={"text"} name={"nom"} id={"nom"}
                                                       placeholder={t("modal_gain_phy.form.nom.placeholder")} {...registerForm2(
                                                    "nom",
                                                    {
                                                        required: {value: true, message: MessageEmpty.nom}
                                                    }
                                                )}/>
                                            </div>
                                        </div>
                                        <div className={"form_row_wrapper"}>
                                            <div className={"input_wrapper"}>
                                                <input type={"text"} name={"prenom"} id={"prenom"}
                                                       placeholder={t("modal_gain_phy.form.prenom.placeholder")} {...registerForm2(
                                                    "prenom",
                                                    {
                                                        required: {value: true, message: MessageEmpty.prenom}
                                                    }
                                                )}/>
                                            </div>
                                        </div>
                                        <div className={"form_row_wrapper"}>
                                            <div className={"input_wrapper"}>
                                                <input type={"text"} name={"adresse"} id={"adresse"}
                                                       placeholder={t("modal_gain_phy.form.adresse.placeholder")} {...registerForm2(
                                                    "adresse",
                                                    {
                                                        required: {value: true, message: MessageEmpty.adresse}
                                                    }
                                                )}/>
                                            </div>
                                        </div>
                                        <div className={"form_row_wrapper"} id={"form_row_wrapper_cp_pays"}>
                                            <div className={"input_wrapper"}>
                                                <input type={"text"} name={"cp"} id={"cp"}
                                                       placeholder={t("modal_gain_phy.form.cp.placeholder")} {...registerForm2(
                                                    "cp",
                                                    {
                                                        required: {value: true, message: MessageEmpty.cp}
                                                    }
                                                )}/>
                                            </div>
                                            <div className={"input_wrapper"}>
                                                <input type={"text"} name={"ville"} id={"ville"}
                                                       placeholder={t("modal_gain_phy.form.ville.placeholder")} {...registerForm2(
                                                    "ville",
                                                    {
                                                        required: {value: true, message: MessageEmpty.ville}
                                                    }
                                                )}/>
                                            </div>

                                        </div>
{/*
                                        <div className={"form_row_wrapper"}>
                                            <div className={"input_wrapper"}>

                                                <select {...registerForm2(
                                                    "pays",
                                                    {
                                                        required: {value: true, message: MessageEmpty.pays}
                                                    }
                                                )} defaultValue={""}>
                                                    <option value={""}
                                                            disabled={true}>{t("modal_gain_phy.form.pays.placeholder")}</option>
                                                    {t("modal_gain_phy.form.pays.value", {returnObjects: true}).map((option, key) =>
                                                        <option key={key} value={option}>{option}</option>)}
                                                </select>
                                            </div>
                                        </div>
*/}
                                        <div className={"form_row_wrapper"}>
                                            <div className={"input_wrapper"}>
                                                <input type={"text"} name={"telephone"} id={"telephone"}
                                                       placeholder={t("modal_gain_phy.form.telephone.placeholder")} {...registerForm2(
                                                    "telephone",
                                                    {
                                                        required: {value: true, message: MessageEmpty.telephone}
                                                    }
                                                )}/>
                                            </div>
                                        </div>
                                        <div className={"error_wrapper"}>
                                            <span>{errorsForm2.nom && errorsForm2.nom.message}</span>
                                            <span>{errorsForm2.prenom && errorsForm2.prenom.message}</span>
                                            <span>{errorsForm2.adresse && errorsForm2.adresse.message}</span>
                                            <span>{errorsForm2.cp && errorsForm2.cp.message}</span>
                                            <span>{errorsForm2.ville && errorsForm2.ville.message}</span>
                                            <span>{errorsForm2.pays && errorsForm2.pays.message}</span>
                                            <span>{errorsForm2.telephone && errorsForm2.telephone.message}</span>
                                        </div>
                                        <input type={"submit"} className={"cta"} value={t("modal_gain_phy.cta")}
                                               ref={submitRef2}/>

                                        <div className={"copyright_mention"}>
                                            <span>{t("copyright")}</span>
                                        </div>
                                    </form>
                                </>
                            }
                        </Modal.Body>
                    </Modal>

                    <Modal id={"modal_game_merci"} className={"modal_carandache"} show={showMerci}
                           onHide={handleCloseMerci}
                           backdrop="static" centered>
                        <Modal.Body>
                            <a className={"modal_close"} onClick={handleCloseMerci}/>
                            <div className={"modal_title"}>{t("modal_merci.title")}</div>
                            <p className={"modal_subtitle"}
                               dangerouslySetInnerHTML={{__html: t("modal_merci.subtitle")}}/>
                            <a onClick={() => {
                                trackAction(
                                    "Lien Caran d'Ache lot",
                                    () => {
                                        window.location.href = 'https://www.carandache.com/'
                                    }
                                 )
                                }} className={"cta px-3"}
                            >{t("modal_merci.cta")}</a>

                            <div className={"copyright_mention"}>
                                <span>{t("copyright")}</span>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal id={"modal_game_steplose"} className={"modal_carandache"} show={showStepLose}
                           onHide={handleCloseStepLose} backdrop="static" centered>
                        <Modal.Body>

                            <div className={"modal_title"}>{t("modal_lose.title")}</div>
                            <p className={"modal_subtitle"}>{t("modal_lose.subtitle")}</p>
                            <a onClick={() => {
                                    trackAction(
                                        "Lien Caran d'Ache perdu",
                                        () => {
                                            window.location.href = 'https://www.carandache.com/'
                                        }
                                    )
                                }}
                               className={"cta"}>{t("modal_lose.cta")}</a>

                               <div className={"copyright_mention"}>
                                   <span>{t("copyright")}</span>
                               </div>

                        </Modal.Body>
                    </Modal>
                </>
            }

            {ope_end || !ope_start && showGameEndModal &&
                <Modal id={"modal_game_end"} className={"modal_carandache"} show={true} backdrop="static" centered>
                    <Modal.Body>
                        <div className={"modal_title"}
                               dangerouslySetInnerHTML={{__html: t("modal_end.title")}}></div>
                    </Modal.Body>

                    <div className={"copyright_mention"}>
                        <span>{t("copyright")}</span>
                    </div>
                </Modal>
            }

            <section id={"strate"}>
                {cases}

                <div id={"case_mobile"} className={"d-lg-none case_" + parseInt(date)} onClick={handleShowStep1}>
                    <span>{date === null && " "}{date !== null && date}</span>
                </div>
            </section>


        </>
    );

};

export default Home;