import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './sass/reset.scss'
import './sass/style_mob.scss'
import './sass/style.scss'
import './sass/style_max.scss'

//traduction
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_en from "./lang/en.json";
import common_fr from "./lang/fr.json";
import common_de from "./lang/de.json";
import common_it from "./lang/it.json";
import common_jp from "./lang/jp.json";

const available_lang = ["fr","de","it","en","jp"];
let lang = localStorage.getItem("lang");

if(!available_lang.includes(lang)){
  lang="fr";
}

i18next.init({
  interpolation: {escapeValue: false},  // React already does escaping
  lng          : lang,                  // language to use
  resources    : {
    en         : {
      common: common_en
    },
    fr     : {
      common: common_fr
    },
    it      : {
      common: common_it
    },
    de      : {
      common: common_de
    },
    jp      : {
      common: common_jp
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App/>
  </I18nextProvider>
  </React.StrictMode>
);
/*
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
