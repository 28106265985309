import React from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const RouteWrapper = ({component : Component,...rest}) => {
/*
  let seo

  const {t} = useTranslation("common");
  const seo_data  = t("seo", {returnObjects: true});

  if(seo_data[rest.path] !== undefined)
    seo = seo_data[rest.path]
  else
    seo = seo_data['default']

  let title = seo.title
  let description = seo.description
  let keywords = seo.keyword
*/

  let title = "Calendrier Caran d'Ache"
  let description = ""
  let keywords = ""
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keyword" content={keywords} />
      </Helmet>

      <Route {...rest} component={Component} />
    </React.Fragment>
  );
}

export default RouteWrapper;