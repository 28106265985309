import React, {useEffect, forwardRef, useImperativeHandle, useState, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const LangSelection = forwardRef(({onShow, onClose}, ref) => {
    const {t} = useTranslation("common");
    const [show, setShow] = useState(false);

    //form
    const {register, handleSubmit, formState: {errors}} = useForm();
    const submitRef = useRef(null);

    //error message
    const MessageEmpty = {
        "lang": t("lang_selection.form.lang.error.0"),
        "pays": t("lang_selection.form.pays.error.0"),
    };

    const trackAction = (action, callback) => {
        fetch (
            process.env.REACT_APP_API_URL + '/eventlog.php',
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                cache: "no-cache",
                body: JSON.stringify({
                    "action": action,
                    "lang": localStorage.getItem("lang"),
                    "country": localStorage.getItem("country")
                })
            }
        )
        .then(response => {
            if (!response.ok) {
                if (callback) {
                    callback()
                }
                return Promise.reject(response)
            }
            return response.json()
        })
        .then(json => {
            if (json.success === true) {
                if (callback) {
                    callback();
                }
            } else {
                callback()
            }
        })
    }

    const handleShow = () => {
        setShow(true);
        if (onShow) onShow();
    }

    const handleClose = () => {
        setShow(false);
        if (onClose) onClose();
    }

    const submitForm = (data) => {

        submitRef.current.setAttribute("disabled", "disabled");
        localStorage.setItem("lang",data.lang);
        localStorage.setItem("country",data.pays);

        if (onClose) onClose();

        setShow(false);
        submitRef.current.removeAttribute("disabled");

        trackAction('Choix Pays')
    }

    const handleLangSelection = (event) => {
        const lang = event.target.value;
        const available_lang = ["fr","de","en","it","jp"];

        if(!available_lang.includes(lang)){
            return false;
        }

        localStorage.setItem("lang",lang);
        i18next.changeLanguage(lang);
    }

    useEffect(() => {
        //controle de la selection de la langue
        const lang = localStorage.getItem("lang");
        const pays = localStorage.getItem("country");

        if(lang === null || pays === null){
            localStorage.removeItem("lang");
            localStorage.removeItem("country");
        }

        i18next.changeLanguage("en");
    }, [])

    useImperativeHandle(ref, () => ({
        handleShow,
        handleClose
    }));

    return (
        <>
            <Modal id={"modal_lang"} className={"modal_carandache"} show={show}
                   backdrop="static" centered>
                <Modal.Body>
                    <div className={"modal_title"}>{t("lang_selection.title")}</div>
                    <div className={"modal_subtitle"} dangerouslySetInnerHTML={{__html: t("lang_selection.subtitle")}}/>
                    <form id={"form_lang_selector"} onSubmit={handleSubmit(submitForm)}>
                        <div className={"lang_selector"}>
                            <div className={"lang_item"} id={"lang_item_uk"}>
                                <label htmlFor={"lang_uk"}></label>
                                <input type={"radio"} id={"lang_uk"} name={"lang"} value={"en"} defaultChecked {...register("lang", {required: {value: true, message: MessageEmpty.lang}})} onClick={handleLangSelection}/>
                            </div>
                            <div className={"lang_item"} id={"lang_item_fr"}>
                                <label htmlFor={"lang_fr"}></label>
                                <input type={"radio"} id={"lang_fr"} name={"lang"} value={"fr"} {...register("lang", {required: {value: true, message: MessageEmpty.lang}})} onClick={handleLangSelection}/>
                            </div>
                            <div className={"lang_item"} id={"lang_item_de"}>
                                <label htmlFor={"lang_de"}></label>
                                <input type={"radio"} id={"lang_de"} name={"lang"} value={"de"} {...register("lang", {required: {value: true, message: MessageEmpty.lang}})} onClick={handleLangSelection}/>
                            </div>
                            <div className={"lang_item"} id={"lang_item_it"}>
                                <label htmlFor={"lang_it"}></label>
                                <input type={"radio"} id={"lang_it"} name={"lang"} value={"it"} {...register("lang", {required: {value: true, message: MessageEmpty.lang}})} onClick={handleLangSelection}/>
                            </div>
                            <div className={"lang_item"} id={"lang_item_jp"}>
                                <label htmlFor={"lang_jp"}></label>
                                <input type={"radio"} id={"lang_jp"} name={"lang"} value={"jp"} {...register("lang", {required: {value: true, message: MessageEmpty.lang}})} onClick={handleLangSelection}/>
                            </div>
                        </div>
                        <div className={"form_row_wrapper"}>
                            <div className={"input_wrapper"}>
                                <select {...register("pays", {required: {value: true, message: MessageEmpty.pays}})}
                                        defaultValue={""}>
                                    <option value={""}
                                            disabled={true}>{t("lang_selection.form.pays.placeholder")}</option>
                                    {t("lang_selection.form.pays.value", {returnObjects: true}).map((option, key) =>
                                        <option key={key} value={option}>{option}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className={"error_wrapper"}>
                            <span>{errors.pays && errors.pays.message}</span>
                            <span>{errors.lang && errors.lang.message}</span>
                        </div>

                        <input type={"submit"} className={"cta"} ref={submitRef} value={t("lang_selection.form.cta")}/>

                        <div className={"copyright_mention"}>
                            <span>{t("copyright")}</span>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    );

})

export default LangSelection;