import React from 'react';
import {BrowserRouter as Router, Redirect, Switch, Route} from 'react-router-dom';
import history from './component/routes/history';
import Layout from './component/_Layout';

import Home from "./component/pages/home";
import LangSelection from "./component/_langSelection";

function App() {

  return (
    <Router>
      <Layout/>
    </Router>
  );
}

export default App;
