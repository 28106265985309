import React from 'react';
import { Helmet } from 'react-helmet';
import {Switch, Redirect} from 'react-router-dom';
import RouteWrapper from './Route';

import seo_data from '../../file/seo.json';

import Home from '../pages/home';
import LangSelection from "../_langSelection";

export default function CustomRoutes() {


  return (
    <>
      <Switch>
        <RouteWrapper path="/" exact component={Home} />
        <RouteWrapper path="/:lang" component={LangSelection} />

        <Redirect to="/" />
      </Switch>
    </>
  );
}